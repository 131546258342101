.footer {
flex: 0 0 0;
padding: 35px 0px;
background-color: #1c1f37;
margin-top: 60px;
user-select: none;
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
    }

    &__title {
    font-size: 25px;
    color: #e6e6e6;
    letter-spacing: 1px;
        a{
            color: #fff;
            text-decoration: none;
            span{
                color: #3e77e2;
            }
        }
    }
    &__nav {
        a{
            color: #727796;
            text-transform: uppercase;
            margin-right: 40px;
            font-size: 14px;
            transition: all 0.2s linear;
            text-decoration: none;

        }
        a:hover{
            color: #808ff2;
        }
        a:last-child{
            margin-right: 0px;
        }
    }

    &__description {
        margin: 35px 0px;
        width: 500px;
        line-height: 22px;
        font-size: 14px;
        letter-spacing: 0.4px;
        font-weight: 500;
        color: #727796;
    }

    &__copyrite {
        color: #727796;
        font-weight: bold;
        font-size: 12px;
        a{
            color: #3e77e2;
            text-decoration: none;
        }
    }

}
