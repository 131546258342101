@keyframes animationOpacity{
    from{
        opacity: 0.1;
    }to{
        opacity: 1;
    }
}
@keyframes jump{
    from{
        transform: translateY(0px);
    }to{
        transform: translateY(-10px);
    }
}
.fadeModal {
    animation-duration: 0.33s;  
    animation-fill-mode: both; 
    animation-name: fadeModal; 
}


@keyframes fadeModal { 
    0% {              
        opacity: 0;
        transform: translate3d(0px, -100%, 0px);
    }
    100% {              
        opacity: 1;
        transform: none;
    }
}

@keyframes top { 
    0% {              
        opacity: 0;
        transform: translate3d(0px, -30%, 0px);
    }
    100% {              
        opacity: 1;
        transform: none;
    }
}

@keyframes left { 
    0% {              
        opacity: 0;
        transform: translate3d(-30%, 0px, 0px);
    }
    100% {              
        opacity: 1;
        transform: none;
    }
}


@keyframes fadeTab { 
    0% {              
        opacity: 0;
    }
    30%{
        opacity: 0;
    }
    100% {              
        opacity: 1;
        transform: none;
    }
}
