.wrap_a32f{
    z-index: 200 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.notranslate{
    z-index: 200 !important;
}

.modal {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #0b0f17dc;
    z-index: 1001;
    user-select: none;
    

    &__notif{
        background-color:  #fff;
        border-radius: 22px;
        border: solid 2px #999;
        color: #1d1d1d;
        font-size: 12px;
        text-align: center;
        width: 100%;
        padding: 9px 12px;
        animation-duration: 0.23s;
        animation-fill-mode: both;
        animation-name: left;
        position: absolute;
        top: -60px;
        left: 0px;
        z-index: 1222;
    }


    &__notif-push{
        background-color:  #fff;
        border-radius: 22px;
        border: solid 2px #999;
        padding: 9px 15px;
        top: -60px;
    }
    &__content, &__contentpush {
        background-color: #1f2233;
        width: 770px;
        height: auto;
        margin-top: 140px;
        position: relative;
        border-radius: 15px;
        margin: 250px auto;
        position: relative;
    }

    &__contentpush{
        width: 330px;
    }

    &__close {
        position: absolute;
        right: 16px;
        top: 2px;
        font-size: 29px;
        opacity: 0.7;
        transition: all .2s linear;
        cursor: pointer;
        color: #cecdcc;
    }
    &__close:hover{
        opacity: 1;
    }

    &__content-info{
        display: block;
        h2{
            text-align: center;
            margin-bottom: 10px;
            font-size: 12px;
            color: #767f95;
        }
    }



    &__title {
        display: flex;
        justify-content: center;
        font-size: 17px;
        align-items: center;
        padding: 13px 0px;
        border-bottom: 2px solid #384157;
        color: #fff;
        font-weight: inherit;
        border-radius: 15px 15px 0px 0px;
        // background-color: #1d1f2b;
        background-color: #3e77e2;

        svg{
            margin-right: 7px;
            margin-bottom: 2px;
        }
    }

    &__content-info{
        display: flex;
        width: 100%;
        align-items: flex-start;
        text-align: center;
        padding: 20px;
    }

    &__block-product{
        display: block;
        padding: 15px 15px;
        width: 69%;
        background-color: #1a1c2a;
        border-radius: 15px;
        margin-right: 15px;
        // border: 2px solid #384157;
    }

    &__right, &__push-inside{
        width: 39%;
        background-color: #1a1c2a;
        border-radius: 11px;
        padding: 15px 17px;
        text-align: start;
        position: relative;
        // border: 2px solid #384157;
    }
    &__handler{
        display: none !important;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: #1a1c2ae7; 
        z-index: 100000;
    }

    &__inner-handler{
        color: #fff;
        text-align: center;
        padding-bottom: 15px;
        span{
            margin-top: 10px;
        }

        h5{
            font-size: 14px;
            width: 200px;
        }
    }
    &__push-inside{
        width: 100%;
    }

    &__block-inner{
        display: flex;
        padding-bottom: 10px;
        align-items: center;
        border-bottom: 2px solid #384157;
    }

    &__description {
        font-size: 15px;
        text-align: initial;
    }

    &__block-product-img{
        height: 68px;
        border-radius: 11px;
        object-fit: cover;
    }

    &__label{
        font-size: 13px;
        color: #686e87;
    }

    &__input{
        margin: 8px 0px;
        padding: 10px 15px;
        align-items: center;
        width: 100%;
        background-color: #161822;
        font-size: 13px;
        font-weight: 600;
        position: relative;
        line-height: 19px;
        color: #c2c5d3;
        border: 2px solid #384157;
        border-radius: 12px;
    }

    &__input::placeholder{
        color: #384157;
    }
    &__input:focus{
        border: 2px solid  #3e77e2;
    }
    &__input::before{
        content: '';
        position: absolute;
        width: 100%;
        top: 2px;
        left: 0px;
        height: 2px;
        background-color: rgb(240, 240, 240);
        z-index: 21;
    }
    &__btn, .modal__btn-push {
        width: 100%;
        line-height: 30px;
        font-size: 13px;
        color: #fff;
        border: 0px;
        background-color: #3e77e2;
        border-radius: 10px;
        transition: background 0.2s linear;
        font-weight: 500;
        margin-top: 7.5px;
    }
    .modal__btn-push{
        margin: 5px 0px 8px 0px;
    }
    &__btn:hover{
        background-color: #3e77e2;
    }

    &__block-cat{
        display: flex;
        align-items: center;
        background-color: #3e77e2;
        padding: 6px 16px;
        font-size: 13px;
        font-weight: 400;
        color: #ffffff;
        border-radius: 13px;
        svg{
            margin-right: 5px;
        }
    }

    &__inform{
        padding: 18px 0px 0px ;
        text-align: start;

        a{
            line-height: 22px;
            font-size: 14px;
            text-decoration: none;
            color: #3e77e2ae;
            border-bottom: 1px dotted #3e77e2ae;
        }

        a:hover{
            border-bottom: 0px solid #e2e1e0
        }

        h4{
            line-height: 22px;
            color: #686e87;
            font-weight: 500;
            font-size: 14px;
        }
    }

    &__curency{
        display: flex;
        text-align: center;
        h1{
            font-size: 20px;
            font-weight: 600;
            color: #fff;
        }
        span{
            color: #686e87;
            font-size: 13px;
            font-weight: 600;
            padding-top: 7px;
            padding-left: 2px;
        }
    }


    &__counter {
        border-radius: 13px;
        border: 1px solid #232837;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        max-width: 122px; 
    }

    &__counter-num {
        display: flex;
        align-items: center;
        color: #1d1d1d;
        border-radius: 5px;
        width: 45px;
        justify-content: center;
        padding: 4px;
        span{
            font-weight: bold;
            color: #fff;
        }
    }

    &__counter-plus {
        padding: 5px 15px;
        background-color: #3e77e2;
        color: #fff;
        cursor: pointer;
    }

    &__counter-minus {
        background-color: #161822;
        color: #fff;
        padding: 5px 15px;
        cursor: pointer;
    }


    &__description {
        padding: 5px 10px;
        width: 100%;
    }

    &__block-inner-info, &__block-inner-info-first{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__block-inner-info-first{
        margin-bottom: 3px;
    }

    &__block-inner-info-title--modal {
        margin: 3px 0px;
        color: #c2c5d3;
        font-size: 14px;
        line-height: 15px;
    }

}
.promo{
    font-size: 11px;
    color: #ccc;
    margin: 10px;
}
.active{
    display: inline-block;
}
.activeHandler{
    display: flex !important;
}

.hide{
    display: none;
}

.message{
    font-size: 17px;
    position: absolute;
    display: flex;
    justify-content: center;
    top: 20px;
    background-color: #fff;
    color: #333;
    border-radius: 22px;
    z-index: 1022;
}


@media (max-width: 780px){
    .modal{
        background-color: rgba(11,15,23,.963)
    }
    .modal__notif{
      top: auto;
      bottom: -50px;
    }
  
    .modal__content, .modal__contentpush{
      width: 88%;
      margin: 20px auto;
    }
  
}


@media (max-width: 780px){
    .modal__content-info{
        display: block;
    }

    .modal__block-product{
        width: 100%;
    }

    .modal__right{
        width: 100%;
        margin-top: 30px;
    }
}

@media (max-width: 535px){
    .modal__right{
        margin-top: 0px;
        border-radius: 10px 10px 0px 0px;
    }
    .modal__content-info{
        padding: 15px 15px;
        background-color: #1f2233;
        border-radius: 0px 0px 12px 12px;
    }
    .modal__block-inner{
        img{
            display: none;
        }
    }
    .modal__block-product{
        padding: 15px 17px;
        border-radius: 0px 0px 10px 10px;
    }
    .modal__block-inner{
        border: 0px;
    }
    .modal__inform h4:nth-child(3){
        display: none;
    }
    .modal__title{
        padding: 10px 0px;
        border: 0px;
    }
    .modal__close{
        top: -1px;
    }
    .modal__content-info{
        display: flex;
        flex-direction: column-reverse;
    }
    .modal__inform{
        padding: 0px 3px 0px;
    }
    .modal__block-cat{
        padding: 7px 12px;
    }
    .modal__counter-plus{
        padding: 5px 14px;
    }
    .modal__counter-minus{
        padding: 5px 14px;
    }
    .modal__counter-num{
        width: 35px;
    }
    .modal__description{
        padding: 5px 4px;
    }
    .modal__block-inner-info-first{
        margin-bottom: 8px;
    }
    .modal__block-inner-info-title--modal{
        font-size: 13px;
    }
    .modal__inform h4{
        font-size: 13px;
    }
    .modal__inform a{
        font-size: 13px;
    }

}
.notif{
    background-image: url('../../../img/ims242.svg');
    background-repeat: no-repeat;
    background-position: left;
    background-size: auto;
    padding-left: 37px;
    background-color: #292f3d;
}