.header{
    flex-shrink: 0;
    top: 0;
    width: 100%;
    color: transparent;
    background-color: #1c1f37;
    z-index: 1000;
    user-select: none;
    &__inner{
        display: flex;
        height: 70px;
        justify-content: space-between;
        align-items: center;
    }
    .burger{
        display: none;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-weight: 500;
        background: 0 0;
        border: 0;
        cursor: pointer;

        .svg-anim{
            animation-duration: 0.33s;  
            animation-fill-mode: both; 
            animation-name: showMenu; 
        }
    }
    .menu {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #1c1f36f6;
        border: 1px solid #212338;
        border-radius: 3px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        z-index: 999;
        color: #e6e6e7;
        animation-duration: 0.33s;  
        animation-fill-mode: both; 
        animation-name: showMenu; 
        
        p{
            margin-bottom: 13px;
            font-weight: bold;
            text-align: center;
            cursor: default;
        }

        a { 
            text-align: start;
            display: block;
            padding: 7px 3px;
            text-decoration: none;
            color: #e6e6e7;
            font-size: 17px;
            margin-top: 15px;
        }

        a::before{
            content: ">";
            margin-right: 7px;
            color: rgb(117, 121, 125);
        }
      }
    &__logo{
        display: flex;
        font-size: 22px;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;
        align-items: center;
        color: #e6e6e7;
        span{
            background: #e6e6e7;
            color: #1c1f36;
            padding: 7px 8px 7px 8px;
            margin-right: 5px;
            border-radius: 2px;
        }
    }
    &__nav{
        a{
            font-size: 15px;
            margin-left: 65px;
            color: #e6e6e7;
            text-transform: uppercase;
            text-decoration: none;
            transition: all 0.2s ease;
            font-weight: bold;
        }

        a:first-child{
            margin-left: 0px;
        }
        a:hover{
            color: #808ff3;
        }
    }
    .header__inner--mypush-link{
        cursor: pointer;
        display: none;
    }
}

@keyframes showMenu { 
    0% {              
        opacity: 0;
        transform: translate3d(0px, 0%, 0px);
    }
    100% {              
        opacity: 1;
        transform: none;
    }
}
