* {
  padding: 0;
  margin: 0;
  border: 0;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}
nav,
footer,
header,
aside {
  display: block;
}
html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
input,
button,
textarea {
  font-family: inherit;
}
input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:focus  {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.pr-bg {
  background-color: #1c1f35;
  padding: 50px 0px 50px;
  margin-top: 90px;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container {
  width: 1050px;
  margin: 0 auto;
  position: relative;
}

.content--named-block,
.content--named-block-first, .content--named-block-s {
  line-height: 23px;
  margin: 0 auto;
  padding: 73px 0px 73px;
  width: 100%;
  text-align: center;
  user-select: none;
  svg {
    width: 25px;
    height: 25px;
    fill: #e6e6e6;
    margin-right: 10px;
  }
  h2 {
    display: inline-flex;
    font-size: 40px;
    color: #e6e6e6;
    font-weight: 600;
    position: relative;
    span {
      margin-left: 20px;
    }
  }

  h2::before {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: -19px;
    height: 3px;
    width: 100%;
    border-radius: 100%;
    background-color: #3e77e2;
  }

  h4 {
    color: #e6e6e6;
    font-size: 14px;
  }
}

.content--named-block-s{
  display: none;
  h3{
    margin-top: 35px;
    color: #e6e6e6;
    font-size: 19px;
    a{
      color: #3e77e2;
    }
  }
}

.content--named-block-first {
  margin: 0px 0px 67px;
  padding: 13px 0px 33px;

  h2 {
    color: #e6e6e6;
  }
  svg {
    fill: #e6e6e6;
  }
}

// Mobil


@media (max-width: 1215px){
  .container{
    width: 100%;
    padding: 0px 30px;
  }
  
  .intro__img-emodji{
    right: -19px;
  }
  .intro__img{
    margin-right: 44px;
  }
}


@media (max-width: 1080px){

  .intro__inner{
    display: block;
    text-align: center;
  }

  .intro__info h2{
    width: 100%;
  }

  .intro__info{
    display: inline-block;
  }
  
  .intro__img-emodji{
    right: auto;
    width: 210px;
    top: 20px;
  }
  .intro__img{
    margin-right: auto;
    width: 200px;
  }
}


@media (max-width: 1000px){
  .product-list__block{
    width: 100%;
  }

  .block-list__block{
    width: 48%;
    margin-bottom: 10px;
    border: solid 2px #2f334f;
  }
  .intro{
    display: none;
  }
  .content--named-block, .content--named-block-first, .content--named-block-s{
    padding: 50px 0px 50px;
  }
  .intro__btn{
    font-weight: 500;
  }
}

@media (max-width: 860px){
  .header__nav{
    display: none;
  }
  .header .burger{
    display: grid;
  }
  .header__inner--mypush-link{
    display: block !important;
    position: absolute;
    right: 75px;
    top: 23.5px;
  }
  .modal__input{
    font-weight: 500;
  }
  .content--named-block-s{
    display: block;
  }
  .content--named-block-s h2{
    font-size: 27px;
  }
  .content--named-block-s h2::before{
    bottom: -16px;
  }

  .content--named-block-s h3{
    font-size: 17px;
    margin-top: 30px;
  }

  .intro__btn{
    font-size: 15px;
    margin-top: 15px;
  }
  .footer__description{
    margin: 0px;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .footer{
    margin-top: 30px;
    padding: 25px 0px;
  }
}


@media (max-width: 630px){
  .block-faq__quest::before{
    top: 14px;
  }
  .block-faq__request{
    margin-top: 15px;
    font-size: 14px;
  }
  .footer__nav{
    display: none;
  }
  .intro__info{
    width: 100%;
  }
}


@media (max-width: 580px){
  .product-list__block{
    display: block;
    height: auto;
  }
  .intro__block{
    display: none;
  }
  .intro{
    height: 625px;
  }

  .product-list__image img{
    width: 100%;
    border-radius: 9px 9px 0px 0px;
    height: auto;
    margin-left: 0px;
  }
  .product-list__content{
    line-height: 25px;
    padding: 13px 7px;
  }
  .footer__description{
    width: 100%;
  }
  .block-list__block{
    width: 100%;
  }
}


@media (max-width: 500px){
  .container{
    padding: 0px 12px;
  }
  .intro__title{
    font-size: 53px;
    line-height: 56px;
  }
  .block-faq__quest{
    padding: 16px 54px 16px 14px;
    font-size: 15px;
  }
  .intro__info h2{
    margin-top: 10px;
  }
  .header__inner--mypush-link{
    right: 55px;
  }
}

@media (max-width: 400px){
  .product-list__title h2{
    font-size: 14px;
  }
  .product-list__btns{
    display: block;
    margin-right: 3px;
    h4{
      text-align: center;
    }
  }

  .intro__btn{
    width: 240px;
  }

  .intro__title{
    font-size: 43px;
    line-height: 46px;
  }
  .intro__info h2{
    font-size: 18px;
  }
  .content--named-block h2, .content--named-block-first h2{
    font-size: 32px;
  }

  .content--named-block{
    padding: 55px 0px 55px;
  }
  .content--named-block-first{
    margin: 0px 0px 38px;
  }
}