@font-face {
  font-family: 'Jost';
  src: url('other/Jost-VariableFont_wght.ttf') format('truetype');
}

/* spinner */
/* #jvlabelWrap{
    background: linear-gradient(95deg, rgb(0, 95, 245) 20%, rgb(0, 95, 245) 80%) !important;
  }
  
  .headerBox_fa9d{
    background: linear-gradient(95deg, rgb(0, 95, 245) 20%, rgb(0, 95, 245) 80%) !important;
  }
  
  .cssLeaf_bc55{
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2232%22%20height%3D%2240%22%20viewBox%3D%220%200%2032%2040%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20fill%3D%22%23005ff5%22%20d%3D%22M0%200h9.02L32%2033.196V40H0z%22%2F%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20fill%3D%22%2318c139%22%20d%3D%22M9%200c3.581.05%2023%205.426%2023%2033.08v.03C18.922%2030.751%209%2019.311%209%205.554V0z%22%2F%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A") !important;
  } */

body {
  margin: 0;
  font-family: 'Jost', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #11142a;
  position: relative;
  font-weight: 500;
  cursor: default;
}


::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #282b3c;
}

