.intro {
    display: flex;
    height: 700px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to top, #11142a, #80808034), url('../../../img/back.jpg');
    object-fit: cover;
    object-position: center top;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    user-select: none;
        &__inner{
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
        }

        &__block{
            position: relative;
        }

        &__info{
            width: 544px;
            display: block;
            line-height: 31px;
            position: relative;
            padding-bottom: 25px;

            h2{
                font-size: 21px;
                font-weight: 500;
                color: #e6e6e7;
                width: 477px;
                margin-top: 5px;
            }
        }

        &__btn{
            display: inline-block;
            width: 317px;
            text-align: center;
            padding: 5px 0px;
            cursor: pointer;
            font-size: 17px;
            background-color: #3e77e2;
            color: #fff;
            font-weight: bold;
            border-radius: 8px; 
            margin-top: 30px;
            border: 2px solid #586ceb;
            transition: all .2s linear;
        }

        &__btn:hover{
            background-color: #808ff2;
        }

        &__title{
            line-height: 72px;
            font-size: 62px;
            font-weight: bold;
            text-transform: uppercase;
            color: #e6e6e7;
        }

        &__img{
            width: 400px;
            border-radius: 120px;
            transform: rotate(4deg);
        }

        &__img-emodji{
            position: absolute;
            top: -27px;
            right: -75px;
            z-index: 1;
            width: 555px;
            animation-name: jump; 
            animation-direction: alternate;
            animation-duration: 1422ms;
            animation-iteration-count: infinite;
        }
}


