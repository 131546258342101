.block-faq {
    user-select: none;
    &__quest {
        display: inline-block;
        width: 100%;
        color: #e6e6e6;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 400;
        padding: 16px 26px;
        margin: 12px 0px;
        border: solid 2px #2f334f;
        background-color: #1c1f37;
        line-height: 25px;
        
        transition: all .2s linear;
        cursor: pointer;
        position: relative;
        letter-spacing: 0.2px;
    }
    &__quest::before {
        content: "+";
        position: absolute;
        right: 17px;
        top: 12px;
        font-size: 24px;
        transition: all .2s ease;
        color: #3e77e2;
        text-align: center;
        border-radius: 50%;
        height: 28px;
        width: 28px;
        font-weight: 500;
    }
    &__quest.active::before{
        content: "+";
        right: 17px;
        transform: rotate(45deg);
        color: #e6e6e6;
        height: 28px;
        width: 28px;
    }
    &__quest.active{
        background-color: #2b2e48;
        color: #e6e6e6;
    }
    &__quest:hover{
        background-color: #2b2e48;
    }
    &__request {
        display: none;
        line-height: 28px;
        font-size: 15px;
        margin-top: 10px;
        font-weight: 400;
        color: #e6e6e6;
        border-radius: 22px;
        width: 100%;
        position: relative;
        animation-duration: 0.33s;
        animation-fill-mode: both;
        animation-name: fadeTab;
        a{
            text-decoration: underline;
            color: #6276f7
        }
    }
}

.active{
    display: block;
    
}
