.product-list {
    width: 100%;
    user-select: none;
        &__inner {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    
        &__block {
            display: flex;
            width: 48%;
            height: 75px;
            margin: 15px 0px;
            align-items: center;
            background-color: #1c1f37;
            border-radius: 10px;
            border: solid 2px #2f334f;
            transition: all 0.2s linear;
            cursor: pointer;
        }
    
        &__block:hover{
            box-shadow: 0px 5px 22px #2f334f;
        }
    
        &__content {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-left: 12px;
            line-height: 22px;
        }
    
        &__title {
            h2{
                color: #dad8d8;
                font-size: 15px;
                font-weight: 500;
            }
            span{
                color: #6f6c6c;
                font-size: 14px;
                display: flex;
                align-items: center;
                svg{
                    margin-right: 5px;
                }
            }
        }
    
        &__btns {
            display: flex;
            align-items: center;
            color: #e6e6e6;
            h4{
                font-weight: 600;
                margin-right: 7px;
    
                span{
                    color: #585a68;
                    margin-left: 2px;
                    font-size: 13px;
                }
            }
        }
    
        &__false,    &__true{
            color: #adadad;
            background-color: #2d3147;
            padding: 3px 13px;
            border-radius: 9px;
            margin-right: 10px;
            font-size: 13px;
            transition: all 0.2s linear;
        }
        &__true{
            background-color: #3e77e2;
            color: #e6e6e6
        }
        &__true:hover{
            background-color: #808ff2;
        }
    
        &__image {
            img{
                height: 57px;
                border-radius: 7px;
                margin-left: 7px;
            }
        }
    }
    