.garant{
    background-color: #eaeaeb;
    padding: 55px 0px;
    margin-top: 90px;
}

.block-list {
width: 100%;
display: flex;
user-select: none;
    &__inner {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &__logo{
        display: inline-block;
        border-radius: 100%;
        padding: 6px 12px;
        background-color: #11142a;
        border-radius: 100%;
    }
    &__block {
        border-radius: 22px;
        display: block;
        padding: 23px 7px;
        text-align: center;
        width: 22%;
        background-color: #1c1f36;
        border: solid 7px #2f334f;
        svg{
            margin-top: 5px;
        }
        h2{
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 10px;
            margin-top: 15px;
            color: #f6f6f6;
        }
        p{
            font-size: 15px;
            padding: 5px 13px;
            line-height: 23px;
            color: #9c9c9c;
            font-weight: 400;
            border-radius: 5px;
        }
        a{
            color: #3e77e2;
            text-decoration: underline;
        }
    }
    &__block:last-child{
        margin-right: 0px;
            svg{
                padding-left: 1px;
                padding-bottom: 2px;
            }
    }
}
